<template>
  <div class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-dialog-centered" ref="modalRef">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">Leave Application Log </h1>
              <button
                  @click="onClickClose"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>

        <hr class="mt-0 mb-0">

        <div class="pb-2">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>Approver Name </th>
                <th>Remarks</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in leaveApplicationLogData" :key="i">
                <td>{{ item.leave_approver.full_name || '' }}</td>
                <td>{{ item.remarks }}</td>
                <td>{{ item.status }}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

const route = useRoute()
const schema = ref({})
let contacts = ref([])
const status = ref([]);
const $store = useStore();

const props = defineProps({
  leaveApplicationLogData: Object,
})

const onClickClose = () => {
  $store.state.isModalOpenTwo = false;
}


</script>
