<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Leave Type</th>
          <th>From Date</th>
          <th>To Date</th>
          <th>Applied For(Days)</th>
          <th>Leave Balance</th>
          <th>Purpose</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in tableItems" :key="i">
          <td>{{ item.leave_policies_name }}</td>
          <td>{{ item.start_date }}</td>
          <td>{{ item.end_date }}</td>
          <td>{{ item.number_of_days }}</td>
          <td>{{ item.remaining_leave_balance }}</td>
          <td>{{ item.purpose }}</td>
          <td>{{ item.status }}</td>
          <td>
            <button v-if="item.status === 'pending' || item.status === 'return'" class="btn btn-primary btn-sm"
                    @click="goToEdit(item)"
                    style="cursor: pointer"
            >
              <i class="fas fa-edit"></i>
            </button>
            <button class="btn btn-primary btn-sm mx-1"
                    @click="viewDetails(item)"
                    style="cursor: pointer"
            >
              <i class="fas fa-eye"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="tableItems.length < 0" class="text-center mt-5">No data</p>
    </div>

    <UpdateLeaveModal
        v-if="store.state.isModalOpenThree"
        :formData="formData"
        @getLeaveApplication="getLeaveApplication"
    />

    <LeaveApplicationDetailsModal
        v-if="store.state.isModalOpenTwo"
        :leaveApplicationLogData="leaveApplicationLogData"
      />

  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import UpdateLeaveModal from '@/components/atom/hrPayroll/UpdateLeaveModal.vue'
import LeaveApplicationDetailsModal from '@/components/molecule/company/hrm-payroll/leave-application/LeaveApplicationDetailsModal.vue';
import {computed, inject, ref} from "vue";
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import {useStore} from "vuex";

const props  = defineProps({
  tableItems     : Object,
  getLeaveApplication : Function
});
const route  = useRoute();
const router = useRouter();
const registerTypeModalRef = ref(null);
const isEdit = ref(false)
const showError = inject('showError');
const showSuccess = inject('showSuccess')
const editData = ref(false)
const loading = ref(false)
const store = useStore()
const leaveApplicationLogData = ref({})
const companyId = computed(() => {
  return route.params.companyId
})
let formData = ref({
  id:'',
  start_date: '',
  end_date: '',
  number_of_days: '',
  adjustment_type: '',
  leave_balance_id: '',
  purpose: '',
  task_assigned_contact_profile_id: '',
  emergency_contact: '',
  status:'pending',
})

const $emit = defineEmits(['getLeaveApplication'])

const {
  fetchSingleLeaveApplication
} = handleHrPayroll()

function setFormData() {
  formData.value.id = editData.value.id
  formData.value.start_date = editData.value.start_date;
  formData.value.end_date = editData.value.end_date;
  formData.value.number_of_days = editData.value.number_of_days;
  formData.value.adjustment_type = editData.value.adjustment_type;
  formData.value.leave_balance_id = editData.value.leave_balance_id;
  formData.value.purpose = editData.value.purpose;
  formData.value.task_assigned_contact_profile_id = editData.value.task_assigned_contact_profile_id;
  formData.value.emergency_contact = editData.value.emergency_contact;
  formData.value.status = editData.value.status;
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

const goToEdit = (item) => {
  isEdit.value = true
  const fetchSingleData = fetchSingleLeaveApplication(item.id, getQuery());
  fetchSingleData.then(res => {
    if (res.status){
      store.state.isModalOpenThree = true;
      editData.value = res.data;
      setFormData();
    }
  });
}

const viewDetails = (item) => {
   store.state.isModalOpenTwo = true;
  const fetchSingleData = fetchSingleLeaveApplication(item.id, getQuery());
  fetchSingleData.then(res => {
    if (res.status){
       leaveApplicationLogData.value = res.data.leave_application_logs
    }
  });
}

</script>
