<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                title="Leave Application"
                btnTitle="Add New"
            />
        </div>
        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickToGo"
                class="tds-vds-query-setter"
            >
              <div  class="col-12 col-sm-6 col-md-2 col-lg-2">
                <label  class="col-form-label">Status</label>
                <v-select
                    placeholder="Select Status"
                    v-model="formData.status"
                    :options="leaveLogStatus"
                    :reduce="name => name.value"
                    label="name"
                />
              </div>
            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2 mb-4">
            <ListTable :tableItems="tableItems" :getLeaveApplication="getLeaveApplication" />
        </div>

        <div class="px-2 position-absolute bottom-0 mt-4">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>

    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { inject, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/hrm-payroll/leave-application/ListTable.vue';
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import { computed } from 'vue';

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
const params = ref({
    offset: 10,
});
const leaveLogStatus = ref([]);

let formData = ref({
  status: 'pending',
})

const token = computed(() => localStorage.getItem('token'));

const {
  fetchLeaveApplication,
  fetchTypes
} = handleHrPayroll()

const onClickToGo = () => {
  getLeaveApplication();
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

const onPageChange = async (page) => {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    await getLeaveApplication();
}
const getLeaveApplication = async () => {
    const query = getQuery();
    loading.value = true
    tableItems.value = []

    await fetchLeaveApplication(query).then(res => {
        if (!res.status){
            store.commit("resetPagination")
            return
        }

        tableItems.value = res.data.data;
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getTypes = async () => {
  let query = getQuery();
  await fetchTypes(query).then(res => {
    if (!res.status){
      leaveLogStatus.value = [];
      return
    }

    if (res.data && res.data.leaveLogStatus) {
      leaveLogStatus.value = res.data.leaveLogStatus;
    }

  }).catch(err => {
    console.log(err)
  })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page;
    if (route.query.start) query += '&start_date=' + route.query.start;
    if (route.query.end) query += '&end_date=' + route.query.end;
    if (formData.value.status) query+= '&status=' + formData.value.status;
    query += '&offset=' + params.value.offset
    return query;
}

onMounted(() => {
    Promise.all([
      getLeaveApplication(),
      getTypes()
    ])
})
</script>
